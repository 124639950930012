import React, {useEffect, useState} from 'react';
import { Layout, Spin } from 'antd';
import '../styles/HomePage.css'
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Projects from './Projects';
import Cookies from 'js-cookie';
import { useOktaAuth } from '@okta/okta-react';

const { Content } = Layout;

const HomePage = () => {
    const [searchValue, setSearchValue] = useState("");
    const [userInitials, setUserInitials] = useState("");
    const [selectedScreen, setSelectedScreen] = useState('Dashboard');
    const [loading, setLoading] = useState(true);
    const { oktaAuth } = useOktaAuth();

    useEffect(() => {
        const fetchUser = async () => {
            if(!userInitials){
                try {
                    const idToken = JSON.parse(localStorage.getItem("okta-token-storage")).idToken;
                    const user = idToken.claims.name;
                    const userInitials = user && user.split(' ').map(n => n[0]).join('');
                    setUserInitials(userInitials);
                  } catch (error) {
                    console.error('Error fetching user:', error);
                    setUserInitials(null);
                  } finally {
                      setLoading(false);
                  }
            }
          };
          fetchUser();
    }, [userInitials]);

    const handleSelectedScreen = (screen) => {
        setSelectedScreen(screen);
    }
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://aicc-tc-popup-dev.cdn.data-nonprod.imaginelearning.com/popup-chunk.js";
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);


    async function checkTokens() {
        const accessToken = await oktaAuth.tokenManager.get('accessToken');
        if (accessToken) {
            Cookies.set('accessToken', accessToken.accessToken, {
                expires: 1,
                path: '/',
                secure: true,
                sameSite: 'None',
                domain:'.imaginelearning.com'
        });
        } else {
          console.log('Access token expired or not available');
        }
    }
      
    checkTokens();

    return (
        <Spin spinning={loading} tip="Loading...">
          <div id="tc-popup"/>
        <div className='main-container'>
            <Sidebar onSelectScreen = {handleSelectedScreen}/>
            <div className='content'>
                <Content>
                    {selectedScreen === 'Dashboard' && <Dashboard userInitials={userInitials}/>}
                    {selectedScreen === 'Projects' && <Projects userInitials={userInitials} searchedValue={searchValue} />}
                </Content> 
            </div>  
      </div>
      </Spin>
    );
};

export default HomePage;